<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="canEdit('button')"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!canEdit('form')"
      @submit.prevent="submit"
    >
      <template>
        <v-autocomplete
          v-model="selectaffiliation"
          :items="affiliations"
          label="所属"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-text-field
          v-model="patternname"
          label="シフト名(出力表示)"
          prepend-icon="mdi-text"
          outlined
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="abbreviation"
          label="略称"
          prepend-icon="mdi-text"
          outlined
          :rules="[Rules.Required]"
        ></v-text-field>
        <!--<TimePicker
          v-model="starttime"
          label="開始時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />-->
        <div class="mb-4">
          <v-icon class="mr-2">
            mdi-clock
          </v-icon>
          <vue-timepicker
            v-model="starttime"
            close-on-complete
            :disabled="!edit"
            :format="timeFormat"
            :minute-interval="minInterval"
            placeholder="時間"
            hour-label="時"
            minute-label="分"
          ></vue-timepicker>
          <label class="ml-5 mr-2">開始時間</label>
        </div>
        <!--<TimePicker
          v-model="endtime"
          label="終了時間"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />-->
        <div class="mb-4">
          <v-icon class="mr-2">
            mdi-clock
          </v-icon>
          <vue-timepicker
            v-model="endtime"
            close-on-complete
            :disabled="!edit"
            :format="timeFormat"
            :minute-interval="minInterval"
            :rules="[Rules.Required]"
            placeholder="時間"
            hour-label="時"
            minute-label="分"
          ></vue-timepicker>
          <label class="ml-5 mr-2">終了時間</label>
        </div>
        <!-- <v-radio-group
          v-model="enterAfterNightShiftFlag"
          row
          label="日跨ぎ:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="なし" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="あり" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group> -->
        <!--<TimePicker
          v-model="breaktime"
          label="休憩時間 (「休憩時間」には「朝休憩時間」と「深夜休憩時間」の時間を含めて入力してください。)"
          prepend-icon="mdi-clock"
          :rules="[Rules.Required]"
          outlined
        />-->
        <v-radio-group
          v-model="selectDayWork"
          row
          label="日跨ぎ:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="しない" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="する" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
        <div class="mb-4">
          <v-icon class="mr-2">
            mdi-clock
          </v-icon>
          <vue-timepicker
            v-model="breaktime"
            close-on-complete
            :disabled="!edit"
            :format="timeFormat"
            :minute-interval="minInterval"
            placeholder="時間"
            hour-label="時"
            minute-label="分"
          ></vue-timepicker>
          <label class="ml-5 mr-2">休憩時間</label>
        </div>
        <div class="mb-4">
          <v-icon class="mr-2">
            mdi-clock
          </v-icon>
          <vue-timepicker
            v-model="midnightBreakTime"
            close-on-complete
            :disabled="!edit"
            :format="timeFormat"
            placeholder="時間"
            :minute-interval="minInterval"
            hour-label="時"
            minute-label="分"
          ></vue-timepicker>
          <label class="ml-5 mr-2">深夜休憩時間</label>
        </div>
        <v-autocomplete
          v-model="midnight"
          :items="execItems"
          label="深夜計算"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="overtime"
          :items="overItems"
          label="残業計算"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-banner shaped>
          「実働時間すべて」の場合は、個人設定に関係なく、残業扱いとなります。
        </v-banner>
        <!-- <v-text-field
          v-model.number="unitPrice"
          label="その他手当1"
          prepend-icon="mdi-numeric"
          type="number"
          step="1"
          outlined
        ></v-text-field> -->
        <v-text-field
          v-model.number="unitPrice2"
          label="その他手当2"
          prepend-icon="mdi-numeric"
          type="number"
          step="1"
          outlined
        ></v-text-field>
        <!-- <v-text-field
          v-model.number="unitPrice3"
          label="応援手当"
          prepend-icon="mdi-numeric"
          type="number"
          step="1"
          outlined
        ></v-text-field>
        <v-text-field
          v-model.number="unitPrice4"
          label="研修手当"
          prepend-icon="mdi-numeric"
          type="number"
          step="1"
          outlined
        ></v-text-field> -->
        <v-radio-group
          v-model="selectDelete"
          row
          label="レコード状態:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import ShiftPattern from "@/models/ShiftPattern";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";
const SelectsDelete = {
  Delete: 0,
  Default: 1
};
export default {
  name: "ShiftPatternDetails",
  components: { VueTimepicker },
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      values: {},
      conditions: {},
      pattern: [],
      affiliations: [],
      workpatterns: [],
      patternid: "",
      patternname: "",
      abbreviation: "",
      starttime: "",
      endtime: "",
      breaktime: "",
      morningBreakTime: "00:00",
      midnightBreakTime: "00:00",
      daytimeActiveHour: "00:00",
      opeTime: "00:00",
      workclassificationid: 0,
      // unitPrice: 0,
      unitPrice2: 0,
      unitPrice3: 0,
      unitPrice4: 0,
      selectaffiliation: { code: "", name: "" },
      selectworkpattern: { code: "0", name: "" },
      midnight: { code: "0", name: "" },
      overtime: { code: "0", name: "" },
      nightshift: { code: "0", name: "" },
      resultpriority: { code: "0", name: "" },
      nightGuardFlag: { code: "0", name: "" },
      // enterAfterNightShiftFlag: { code: "0", name: "" },
      enterAfterNightShiftFlag: SelectsDelete.Delete,
      nightTelFlag: { code: "0", name: "" },
      timeZoneFlag: { code: "0", name: "" },
      confirmFlag: { code: "0", name: "" },
      patternOrder: 9999,
      execItems: [],
      existeItems: [],
      actualItems: [],
      timeZomeItems: [],
      confirmItems: [],
      overItems: [],
      selectDayWork: SelectsDelete.Delete,
      selectDelete: SelectsDelete.Default,
      SelectsDelete,
      shainConfirmation: false,
      timeFormat: "HH:mm",
      minInterval: 15,
      shiftPatternName: ""
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    canEdit(param) {
      const authority = this.$store.state.authority;
      if (authority !== "3") {
        return this.edit;
      }
      if (this.confirmFlag.code === "1") {
        return param === "button" ? true : false;
      } else {
        return this.edit;
      }
    },
    async postRecord(param) {
      const data = await this.$post(this.Paths.shiftpattern, param);
      console.log("post", data);
      //this.patternid = data.shiftPatternId;
      //console.log("generate id", this.patternid);
      //this.add = false;
      return data;
      //
    },
    async putRecord(param) {
      const response = await this.$put(this.Paths.shiftpattern, param);
      return response;
      //return this.$error(response.message);
      //console.log("put", response);
    },
    complete() {
      console.log("complete", this.patternname);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit");
      console.log("starttime", this.starttime);
      console.log("endtime", this.endtime);
      console.log("isNightShift", this.selectDayWork);

      if (this.starttime == '') {
        this.$error("開始時間が設定されていません。");
        return;
      }

      if (this.endtime == '') {
        this.$error('終了時間が設定されていません。');
        return;
      }

      if (this.breaktime == '') {
        this.$error('休憩時間が設定されていません。');
        return;
      }

      this.$loading();
      try {
        const param = new ShiftPattern(
          this.patternid,
          this.selectaffiliation.code,
          this.patternname, //? this.patternname : this.abbreviation,
          this.abbreviation,
          this.starttime,
          this.endtime,
          this.breaktime,
          "00:00:00",
          this.midnightBreakTime,
          this.daytimeActiveHour,
          this.midnight.code,
          this.overtime.code,
          this.nightshift.code,
          this.resultpriority.code,
          this.nightGuardFlag.code,
          this.enterAfterNightShiftFlag,
          this.nightTelFlag.code,
          this.timeZoneFlag.code,
          this.patternOrder,
          this.confirmFlag.code,
          0,
          this.unitPrice2,
          this.unitPrice3,
          this.unitPrice4,
          this.selectDelete,
          this.shainConfirmation,
          "00:00:00",
          this.selectDayWork
        );

        console.log("add", this.add);
        console.log("add.param", param);
        let response;
        if (this.add) {
          response = await this.postRecord(param);
          //this.selectaffiliation.code = param.affiliationId;
          //this.getResponse(response);
          if (response.status === "FAIL") {
            this.$error(response.message);
          } else {
            this.patternid = response.shiftPatternId;
            this.affiliations = this.affiliations.filter(
              e => e.code === String(response.affiliationId)
            );
            //this.selectaffiliation.id = response.affiliationId;
            console.log("generate id", this.patternid);
            console.log("aff id", response.affiliationId);
            this.add = false;
            this.$info("更新しました。", "シフトパターン");
            //this.add = false;
          }

          //this.add = false;
          //this.$info("更新しました。", "シフトパターン");
          //return;
        } else {
          response = await this.putRecord(param);
          if (response.status === "SUCCESS") {
            this.shainConfirmation = await this.$confirm(
              "現状届に使用されているシフトです。 \n変更を行うとシフトの設定時間が変更されますがよろしいですか？"
            );
            if (!this.shainConfirmation) return;
            param.shainConfirmation = this.shainConfirmation;
            console.log("ss", param);
            const responseFinal = await this.putRecord(param);
            this.getResponse(responseFinal);
            this.shainConfirmation = false;
            console.log("confirmation", this.shainConfirmation);
          } else this.getResponse(response);
        }
        //const response = await this.putRecord(param);

        /*let confirm;
        console.log("submit_param", param);
        const alreadyExist = await this.checkShainExpansionWork(param);
        console.log("ddd", alreadyExist);
        //if (alreadyExist === undefined) { confirm = true; }
        if (alreadyExist.status === "SUCCESS") {
          confirm = await this.$confirm(
            "現状届に使用されているシフトです。 \n変更を行うとシフトの設定時間が変更されますがよろしいですか？"
          );
        } else confirm = true;
        console.log(confirm);
        if (!confirm) return;
        let response;
        if (this.add) {
          response = await this.postRecord(param);
        } else {
          response = await this.putRecord(param);
        }
        this.getResponse(response);
        */
        /*const isExist = await this.checkShainExpansionWork(param);
        console.log("eiss", isExist);
        if (isExist.status === "SUCCESS") {
          this.$warning(isExist.message);
          let response;
          if (this.add) {
            response = await this.postRecord(param);
          } else {
            response = await this.putRecord(param);
          }
          this.getResponse(response);
        }
        else {
          console.log("ffsdf");
        }*/
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },

    async checkShainExpansionWork(param) {
      const query = { shiftPatternId: param.shiftPatternId };
      const encode = encodeURI(JSON.stringify(query));
      const data = await this.$get(this.Paths.shainExpWork, "query=" + encode);
      console.log("ssss", data);
      return data;
    },
    getResponse(response) {
      //console.log("reed", response);
      //console.log("reed", response.status);
      if (response.status === "FAIL") {
        this.$error(response.message);
      } else {
        this.$info("更新しました。", "シフトパターン");
        this.edit = false;
      }
    },
    back() {
      const path = "/list/shift-pattern";
      // this.$router.push(path);
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getShozoku() {
      console.log("getShozoku");
      return await this.$get(this.Paths.shozokuFilter);
    },
    async getWorkClassification() {
      console.log("getWorkClassification");
      return await this.$get(this.Paths.workClassification);
    },
    async getCodeMasterItems() {
      console.log("getCodeMasterItems");
      return await this.$get(this.Paths.codeMasterPulldown);
    }
  },
  async created() {
    console.log(this.name, "created", "gggggggggg");
    this.params = this.$route.params;
    console.log("filter", this.params.filter);
    this.$loading();
    try {
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      // 所属プルダウンデータ取得
      this.affiliations = await this.getShozoku();
      const masters = await this.getCodeMasterItems();

      this.execItems = masters.filter(e => e.refcode === CodeGroup.EXEC);
      this.existeItems = masters
        .filter(e => e.refcode === CodeGroup.EXISTE)
        .filter(e => e.code !== NotSetCode.EXISTE);
      this.actualItems = masters.filter(e => e.refcode === CodeGroup.ACTUAL);
      this.timeZomeItems = masters.filter(e => e.refcode === CodeGroup.TZONE);
      this.confirmItems = masters.filter(e => e.refcode === CodeGroup.CONFIR);
      this.overItems = masters.filter(e => e.refcode === CodeGroup.OVER);

      if (!this.add) {
        this.patternid = this.args.shiftPatternId;
        this.affiliations = this.affiliations.filter(
          e => e.code === String(this.args.affiliationId)
        );
        if (this.affiliations.length > 0) {
          this.selectaffiliation.code = this.affiliations[0].code;
          this.selectaffiliation.name = this.affiliations[0].name;
        }

        this.patternname = this.args.shiftPatternName;
        this.abbreviation = this.args.abbreviation;
        this.starttime = this.args.startTime;
        this.opeTime = this.args.opeTime;
        this.endtime = this.args.endTime;
        console.log("breaktime", this.args.breakTime);
        this.breaktime = this.args.breakTime;
        this.morningBreakTime = this.args.morningBreakTime;
        this.midnightBreakTime = this.args.midnightBreakTime;
        this.daytimeActiveHour =
          this.args.daytimeActiveHour == null
            ? "00:00"
            : this.args.daytimeActiveHour;
        let val =
          this.args.midnightTimeFlag == null ? "0" : this.args.midnightTimeFlag;
        let filterVal = this.execItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.midnight.code = filterVal[0].code;
          this.midnight.name = filterVal[0].name;
        }

        val = this.args.overTimeFlag == null ? "0" : this.args.overTimeFlag;
        filterVal = this.overItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.overtime.code = filterVal[0].code;
          this.overtime.name = filterVal[0].name;
        }

        val = this.args.nightShiftFlag == null ? "0" : this.args.nightShiftFlag;
        filterVal = this.existeItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.nightshift.code = filterVal[0].code;
          this.nightshift.name = filterVal[0].name;
        }

        val =
          this.args.resultPriorityFlag == null
            ? "0"
            : this.args.resultPriorityFlag;
        filterVal = this.actualItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.resultpriority.code = filterVal[0].code;
          this.resultpriority.name = filterVal[0].name;
        }

        val = this.args.nightGuardFlag == null ? "0" : this.args.nightGuardFlag;
        filterVal = this.existeItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.nightGuardFlag.code = filterVal[0].code;
          this.nightGuardFlag.name = filterVal[0].name;
        }

        val =
          this.args.enterAfterNightShiftFlag == null
            ? SelectsDelete.Delete
            : SelectsDelete.Default;
        filterVal = this.existeItems.filter(e => e.code === val);
        // if (filterVal.length > 0) {
        //   this.enterAfterNightShiftFlag.code = filterVal[0].code;
        //   this.enterAfterNightShiftFlag.name = filterVal[0].name;
        // }

        val = this.args.nightTelFlag == null ? "0" : this.args.nightTelFlag;
        filterVal = this.existeItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.nightTelFlag.code = filterVal[0].code;
          this.nightTelFlag.name = filterVal[0].name;
        }
        this.patternOrder = this.args.patternOrder;

        this.unitPrice = this.args.unitPrice;
        this.unitPrice2 = this.args.unitPrice2;
        this.unitPrice3 = this.args.unitPrice3;
        this.unitPrice4 = this.args.unitPrice4;

        val = this.args.timeZone === null ? "0" : this.args.timeZone;
        filterVal = this.timeZomeItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.timeZoneFlag.code = filterVal[0].code;
          this.timeZoneFlag.name = filterVal[0].name;
        }

        val =
          this.args.workClassificationId === null
            ? "0"
            : String(this.args.workClassificationId);
        filterVal = this.confirmItems.filter(e => e.code === val);
        if (filterVal.length > 0) {
          this.confirmFlag.code = filterVal[0].code;
          this.confirmFlag.name = filterVal[0].name;
        }

        if (this.args.isValid === "0") {
          this.selectDelete = this.SelectsDelete.Delete;
        }

        if (this.args.isNightShift === "1") {
          this.selectDayWork = this.SelectsDelete.Default;
        } else {
          this.selectDayWork = this.SelectsDelete.Delete;
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-banner__wrapper {
  padding-top: 0px !important;
}
</style>
